import Vocabulary from "../../../../Common/Vocabulary";

export class NoRoutes {
    constructor({ page, lang }) {
        this.$page = page;

        this._lang = lang;

        this.$resultWrapper = $(this.$page).find('.bus_results_list');
    }

    hide() {
        this.$resultWrapper.find('.result__none').remove();
    }

    show() {
        this.$resultWrapper.html(`
            <div class="results_banner">
                <div class="results_banner_left">
                    <img src="/frontend-assets/images_new/bus_results/return-bus-ticket.svg" alt="return-bus-ticket" class="results_banner_img _pc">
                    <div class="results_banner_content">
                        <div class="results_banner_title">
                            <img src="/frontend-assets/images_new/bus_results/return-bus-ticket.svg" alt="return-bus-ticket" class="results_banner_img _mob">
                            <span>${Vocabulary[this._lang].no_routes_title}</span>
                        </div>
                        <div class="results_banner_text">${Vocabulary[this._lang].no_routes}</div>
                    </div>
                </div>
            </div>
        `);
    }
}
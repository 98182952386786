import { Button } from '../../../../Common/AbstractComponents/Button';

export class Swap {
    constructor({ searchModule, cityFrom, cityTo }) {
        this.$searchModule = searchModule;

        this._cityFrom = cityFrom;
        this._cityTo = cityTo;

        this.$revertButton = $(this.$searchModule).find('.reverse');

        this._btn = new Button({
            button: this.$revertButton,
            onClick: this._revert.bind(this),
        })
    }

    _revert() {
        const cityFrom = this._cityFrom.getSelectedCity();
        const citiesFrom = this._cityFrom.getAllCities();
        const cityTo = this._cityTo.getSelectedCity();
        const citiesTo = this._cityTo.getAllCities();

        this._cityFrom.setAllCities(citiesTo);
        this._cityFrom.setSelectedElement(cityTo);
        this._cityTo.setAllCities(citiesFrom);
        this._cityTo.setSelectedElement(cityFrom);
    }
}
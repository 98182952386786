import { Button } from "../../../../Common/AbstractComponents/Button";

export class Filters {
    constructor({ page, updateRoutes, getRoutes }) {
        this.$page = page;

        this._updateRoutes = updateRoutes;
        this._getRoutes = getRoutes;

        this.$filtersSidebar = $(this.$page).find('.bus_results_sidebar .bus_fiters');
        this.$filterBtn = this.$filtersSidebar.find('button.filter_btn');
        this.$resetBtn = this.$filtersSidebar.find('button.reset_btn');

        this._button = new Button({
            button: this.$filterBtn,
            onClick: this._filter.bind(this),
        });

        this._resetButton = new Button({
            button: this.$resetBtn,
            onClick: this._resetFilters.bind(this),
        })

        this._filtersTemplate = {
            change: {
                straight: {
                    selected: false,
                    count: 0,
                },
                transfer: {
                    selected: false,
                    count: 0,
                }
            },
            departure_point: {},
            arrival_point: {},
            transport_model: {},
            carrier_name: {},
        };

        this._filters = {};

        this.init();
    }

    init() {
        const routes = this._getRoutes();
        this._filters = JSON.parse(JSON.stringify(this._filtersTemplate));
        this.$filtersSidebar.find('.bus_fiters_block.change').addClass('_show');

        routes.forEach(route => {
            if (route.departure_point.address) {
                this.$filtersSidebar.find('.bus_fiters_block.departure_point').addClass('_show');
                if (!this._filters.departure_point.hasOwnProperty(route.departure_point.address)) this._filters.departure_point[route.departure_point.address] = {count: 1, selected: false};
                else this._filters.departure_point[route.departure_point.address].count = this._filters.departure_point[route.departure_point.address].count + 1;
            }

            if (route.arrival_point.address) {
                this.$filtersSidebar.find('.bus_fiters_block.arrival_point').addClass('_show');
                if (!this._filters.arrival_point.hasOwnProperty(route.arrival_point.address)) this._filters.arrival_point[route.arrival_point.address] = {count: 1, selected: false};
                else this._filters.arrival_point[route.arrival_point.address].count = this._filters.arrival_point[route.arrival_point.address].count + 1;
            }

            if (route.transport_model) {
                this.$filtersSidebar.find('.bus_fiters_block.transport_model').addClass('_show');
                if (!this._filters.transport_model.hasOwnProperty(route.transport_model)) this._filters.transport_model[route.transport_model] = {count: 1, selected: false};
                else this._filters.transport_model[route.transport_model].count = this._filters.transport_model[route.transport_model].count + 1;
            }

            if (route.carrier_name) {
                this.$filtersSidebar.find('.bus_fiters_block.carrier_name').addClass('_show');
                if (!this._filters.carrier_name.hasOwnProperty(route.carrier_name)) this._filters.carrier_name[route.carrier_name] = {count: 1, selected: false};
                else this._filters.carrier_name[route.carrier_name].count = this._filters.carrier_name[route.carrier_name].count + 1;
            }
        });

        this._renderFilters();
    }

    _renderFilters() {
        for (const key in this._filters) {
            const renderBlock = this.$filtersSidebar.find(`.bus_fiters_block.${key}`);

            let filters = '';

            for (let filter_name in this._filters[key]) {
                const value = filter_name === 'straight' ? 'Прямой' : filter_name === 'transfer' ? 'С пересадкой' : filter_name;
                const name = filter_name === 'straight' ? 'straight' : filter_name === 'transfer' ? 'transfer' : filter_name;
                const count = filter_name === 'straight' ? '' : filter_name === 'transfer' ? '' : this._filters[key][filter_name].count;

                filters += `
                    <div class="_input_checkbox">
                        <input type='checkbox' name='${name}' value='${name}' id='${value}' class='${value}'>
                        <label for='${value}'>
                            <span>${value}</span>
                            <span class="_count">${count}</span>
                        </label>
                    </div>
                `;
            }

            renderBlock.find('.filters_list').html(filters);
        }
    }

    _filter() {
        const selectedCheckox = this.$filtersSidebar.find('input[type="checkbox"]:checked');
        if (selectedCheckox.length === 0) return $(this.$page).find('.bus_results_item').show();
        this._resetFilterValues(this._filters);

        selectedCheckox.each((index, filter) => {
            const type = $(filter).closest('.bus_fiters_block').data('filter');
            this._filters[type][$(filter).val()].selected = true;
        });

        const routes = this._getRoutes();
        routes.forEach(route => {
            const routeElement = $(this.$page).find(`.bus_results_item[data-routeid="${route.route_id}"]`);
            route.change = Array.isArray(route.change) ? route.change.length : Number.isFinite(route.change) ? route.change : 0;

            if (route.change === 0 && this._filters.change.straight.selected) {
                return routeElement.show();
            }

            if (route.change > 0 && this._filters.change.transfer.selected) {
                return routeElement.show();
            }

            if (route.departure_point.address && this._filters.departure_point[route.departure_point.address].selected) {
                return routeElement.show();
            }

            if (route.arrival_point.address && this._filters.arrival_point[route.arrival_point.address].selected) {
                return routeElement.show();
            }

            if (route.carrier_name && this._filters.carrier_name[route.carrier_name].selected) {
                return routeElement.show();
            }

            if (route.transport_model && this._filters.transport_model[route.transport_model].selected) {
                return routeElement.show();
            }

            return routeElement.hide();
        });
    }

    _resetFilterValues(filters) {
        for(let key in filters) {
            if (!filters[key].hasOwnProperty('selected')) {
                this._resetFilterValues(filters[key])
            } else {
                filters[key].selected = false;
            }
        }

        return filters;
    }

    _resetFilters() {
        this._resetFilterValues(this._filters);
        $(this.$page).find('.bus_results_item').show();
        $(this.$filtersSidebar).find('input[type="checkbox"]').prop('checked', false);
    }

    hide() {
        this.$filtersSidebar.find('.bus_fiters_block').removeClass('_show');
        $(this.$filtersSidebar).hide();
    }

    show() {
        $(this.$filtersSidebar).show();
    }
}
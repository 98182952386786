export class Util {
    static setToken(newToken) {
        $('meta[name="csrf-token"]').attr('content', newToken);
        $('input[name="_token"]').val(newToken);
    }

    static token() {
        return {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        };
    }

    static bodyNoScrollOn() {
        $('body').addClass('no-scroll');
    }

    static bodyNoScrollOff() {
        $('body').removeClass('no-scroll');
    }

    static getLocalization() {
        return $('html').attr('lang') === 'ru-ua' ? '/ru/' : '/';
    }

    static getCurrentLang() {
        return $('html').attr('lang');
    }

    static getIsUserRegistered() {
        const element = $('meta[name="isUserRegistered"]');
        if (element.length === 0) return null;

        return element.attr('content');
    }

    static priceFormat(price) {
        return new Intl.NumberFormat(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(price);
    }
}
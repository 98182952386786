import DeclensionNouns from "../../../../Common/DeclensionNouns";
import Vocabulary from "../../../../Common/Vocabulary";
import PassengersType from "../../Checkout/Data/PassengersType";

export class Passengers {
    constructor({ searchModule }) {
        this.$searchModule = searchModule;

        this.$passengersList = $('.passengers .passengers__list');

        this.$addultsInput = $(this.$passengersList).find('input[data-type="addults"]');
        this.$childrenInput = $(this.$passengersList).find('input[data-type="children"]');

        this.$passengerInput = $('.widget_input__passengers ._input');

        this._passengers = [
            {
                type: PassengersType.ADDULTS,
                age: 0,
                place: null,
            },
        ];

        this._lang = $('html').attr('lang');

        this._init();
    }

    _init() {
        this.$passengerInput.on('click', this._togglePassengerWidget.bind(this));

        $('.passengers_select ._calc._plus').on('click', this._addPassenger.bind(this));
        $('.passengers_select ._calc._minus').on('click', this._removePassenger.bind(this));


        const addultsCount = $(this.$searchModule).find('input[data-type="addults"]').val();
        const childrenCount = $(this.$searchModule).find('input[data-type="children"]').val();

        if (addultsCount >= 1) {

            const addultsPassengers = this._passengersParseByCount(PassengersType.ADDULTS, addultsCount);
            const childrenPassengers = this._passengersParseByCount(PassengersType.CHILDREN, childrenCount);

            this._passengers = [...addultsPassengers, ...childrenPassengers];

            $(this.$addultsInput).val(addultsCount);
            $(this.$childrenInput).val(childrenCount);

            this._changePassengerInput();
        }

        let valuePass = ''
        $('.count').each((index, element) => {
            const type = $(element).closest('.passengers__item-right').find('.count').data('type');
            const count = this._passengersCountByType(type);
            if (count <= 0) return;

            valuePass += count + $(element).closest('.passengers__item').find('.passengers__item-left p').text().toLowerCase()

            this.$passengerInput.html(valuePass);
        });
    }

    _togglePassengerWidget() {
        this.$passengerInput.closest('.widget_input__passengers').find('.passengers_select').toggleClass('_show');
    }

    _passengersHandler(event) {
        event.preventDefault();

        if ($(event.target).hasClass('plus') && !$(event.target).closest('.passengers__item').hasClass('age')) {
            this._addPassenger(event.target);
        }

        if ($(event.target).hasClass('minus') && !$(event.target).closest('.passengers__item').hasClass('age')) {
            this._removePassenger(event.target);
        }
    }

    _passengersCountByType(type) {
        return this._passengers.filter(passenger => passenger.type === type).length
    }

    _passengersParseByCount(type, count) {
        const passengers = [];

        for (let i = 1; i <= count; i++) {
            passengers.push({
                type,
                age: 0,
                place: null,
            });
        }

        return passengers;
    }

    _addPassenger(element) {
        let inputElement = $(element.target).closest('.value').find('input');
        const type = inputElement.data('type');

        if (type === PassengersType.CHILDREN && this._passengersCountByType(PassengersType.CHILDREN) < 6) {
            let quantity_val = parseInt(inputElement.val());
            inputElement.val(quantity_val + 1);
            this._passengers.push({
                type: PassengersType.CHILDREN,
                age: 1,
                place: null,
            });
            this._changePassengerInput();
            return true;
        }

        if (type === PassengersType.ADDULTS) {
            let quantity_val = parseInt(inputElement.val());
            inputElement.val(quantity_val + 1);
            this._passengers.push({
                type: PassengersType.ADDULTS,
                age: 0,
                place: null,
            });
            this._changePassengerInput();
            return true;
        }

        return false;
    }

    _removePassenger(element) {
        let inputElement = $(element.target).closest('.value').find('input');
        const type = inputElement.data('type');
        let quantity_val = parseInt(inputElement.val());
        if (quantity_val == 0) return;

        if (type === PassengersType.ADDULTS && quantity_val === 1) return;

        inputElement.val(quantity_val - 1);

        const removeIndex = this._passengers.findLastIndex(passenger => passenger.type === type);
        this._passengers.splice(removeIndex, 1);

        this._changePassengerInput();
    }

    getPassengers() {
        return this._passengers;
    }

    getPassengersUrlString() {
        let passengers = '';
        this._passengers.forEach((passenger, index) => {
            passengers += `&passengers[${index}][type]=${passenger.type}`;
            if (passenger.type === PassengersType.CHILDREN)
                passengers += `&passengers[${index}][age]=${passenger.age}`;
        });

        return passengers;
    }

    getPassengersCount() {

        const passengers = {
            addults: 0,
            children: 0,
        };

        this._passengers.forEach(passenger => {
            passengers[passenger.type] += 1;
        });

        return passengers;
    }

    _changePassengerInput() {
        const passengers = this.getPassengersCount();

        let value = `${passengers.addults} ${DeclensionNouns(passengers.addults, Vocabulary[this._lang].addults_bus.split('|'))}`;

        if (passengers.children > 0) {
           value = `${value}, ${passengers.children} ${DeclensionNouns(passengers.children, Vocabulary[this._lang].children_bus.split('|'))}` 
        }

        $('.widget_input__passengers ._input').html(value);
    }
}
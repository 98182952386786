export class Button {
    constructor ({button, onClick}) {
        this._button = button;
        this._onClick = onClick;
        this._handleClick = this._handleClick.bind(this);

        $(this._button).off();
        $(this._button).on('click', this._handleClick);
        $(this._button).on('mousedown', event => event.stopPropagation());
    }

    _handleClick (event) {
        event.preventDefault();

        this._onClick(event);
    }

    destroy () {
        $(this._button).off();
    }
}
import { Button } from "../../../Common/AbstractComponents/Button";

export class SearchPopupTimer {
    constructor({ page, handler }) {
        this.$page = page;
        this._handler = handler;

        this._timer = null;
        this._popupDelay = 10 * 60 * 1000;

        this.$overlay = $('.bus_overlay');
        this.$popup = $('#update_search');

        this._btnUpdate = new Button({
            button: this.$popup.find('#btn_update'),
            onClick: this._updateRoutes.bind(this),
        });

        this._btnClose = new Button({
            button: this.$popup.find('#btn_close'),
            onClick: this._closePopup.bind(this),
        })
    }

    _startTimer() {
        this.clearTimer();
        this._timer = setTimeout(() => {
            this._showPopup();
        }, this._popupDelay);
    }

    _showPopup() {
        $('html').addClass('_open_bus_popup');
        this.$overlay.addClass('_active');
        this.$popup.addClass('_active');
    }

    _closePopup() {
        $('html').removeClass('_open_bus_popup');
        this.$overlay.removeClass('_active');
        this.$popup.removeClass('_active');
    }

    _updateRoutes() {
        this._closePopup();
        this._handler();
    }

    clearTimer() {
        clearTimeout(this._timer);
    }

    resetTimer() {
        this._startTimer();
    }
}
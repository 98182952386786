import { Button } from '../../../../Common/AbstractComponents/Button';


export class SearchTickets {
    constructor({ searchModule, handler }) {
        this.$searchModule = searchModule;
        

        this.$searchBtnElement = $(this.$searchModule).find('.bus-search__btn');

        this._btn = new Button({
            button: this.$searchBtnElement,
            onClick: handler,
        });
    }
}
import AjaxBuiler from '../../../../Common/AjaxBuilder';
import { City } from './City';

export class CityFrom extends City {
    constructor({ searchModule, loader, lang, changeDatepickerHandler }) {
        super({ module: searchModule, lang: lang, changeDatepickerHandler: changeDatepickerHandler, });

        this._lang = lang;

        this.$inputElement = $(this.$searchModule).find('#departureCity');
        this.$locationWrapper = $(this.$inputElement).closest('.widget_input__from').find('.widget_locations');

        this._cities = [];
        this._popularCities = [];

        this._selectedCityElement = null;

        this._init();
    }

    _init() {
        this.$inputElement.on('focus', () => {
            $(this.$locationWrapper).addClass('_show');
        });

        this.$inputElement.on('input', this._searchCityHandler.bind(this));
        this.$locationWrapper.on('click', this._cityHandler.bind(this));
    }

    getCities() {
        const selectedCity = this._takeSelectedCity('departure_city');
        const selectedCityName = this._takeSelectedCity('departure_city_name');
        //Если города не выбраны, то предложить популярные
        if (!selectedCity) {
            this._getPopularCities('departure');
            return false;
        } 

        return new Promise(resolve => {
            new AjaxBuiler({
                route: this._searchCityRoute,
                data: {
                    city: selectedCityName,
                }
            }, 'GET').execute(
                {
                    success: (data) => {
                        this._cities = data.cities;
                        this._createCityList();

                        const city = this._cities.find(city => city.id == selectedCity);

                        if (city) {
                            this._selectedCityElement = this.$locationWrapper.find(`li[data-id="${city.id}"]`);
                            this._selectedCityElement.addClass('_selected');
                            this.$inputElement.val(this._selectedCityElement.data('name'));
                            return resolve(data.cities);
                        }
                    }
                }
            );
        });
    }
}
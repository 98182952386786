export class FilterDate {
    constructor({ page, datepicker, handler }) {
        this.$page = page;

        this.$dateFilterWrapper = $(this.$page).find('.bus_results_wrap .dates');

        this._datepicker = datepicker;
        this._handler = handler;

        this._init();
    }

    _init() {
        $(this.$dateFilterWrapper).on('click', '.swiper-slide', this._changeDate.bind(this));
    }

    renderDates(view) {
        $(this.$dateFilterWrapper).html(view);
    }

    initSlider() {
        let swiper = new Swiper(".bus_results .dates .swiper", {
            spaceBetween: 0,
            slidesPerView: 6,
            initialSlide: 10,
            navigation: {
                nextEl: ".dates_btn._next",
                prevEl: ".dates_btn._prev",
            },
            breakpoints: {
                300: {
                    slidesPerView: 3,
                },
                600: {
                    slidesPerView: 5,
                },
                768: {
                    slidesPerView: 6,
                }
            }
        });
    }

    _changeDate(event) {
        $('.swiper-slide').removeClass('active');
        const parent = $(event.target).parent('.swiper-slide');
        $(parent).addClass('active');

        const date = $(parent).data('date');

        this._datepicker.setDateStart(date);
        this._handler();
    }

    show() {
        $(this.$dateFilterWrapper).show();
    }

    hide() {
        $(this.$dateFilterWrapper).hide();
    }
}
import { CityFrom } from "./Components/CityFrom";
import { CityTo } from "./Components/CityTo";
import { Datepicker } from "./Components/Datepicker";
import { FilterDate } from "./Components/FilterDate";
import { Filters } from "./Components/Filters";
import { Loader } from "./Components/Loader";
import { Progress } from "./Components/Progress";
import { ItemsWrapper } from './Components/ItemsWrapper';
import { SearchTickets } from "./Components/SearchTickets";
import { Sort } from "./Components/Sort";
import { Passengers } from "./Components/Passengers";
import { NoRoutes } from "./Components/NoRoutes";
import AjaxBuilder from "../../../Common/AjaxBuilder";
import { Util } from "../../../Common/Util";
import { Swap } from "./Components/Swap";
import PassengersType from "../Checkout/Data/PassengersType";
import SearchStepTypes from "../../../Common/SearchStepTypes";
import { BusReturnTicketSelector } from "./Components/BusReturnTicketSelector";
import { SearchPopupTimer } from "../../CommonComponents/Search/SearchPopupTimer";

export class BusSearchModule {
    constructor({ page, handler, success, error, route, search }) {
        this.$page = page;
        this.$searchModule = $('#widget_form');

        this._route = route;
        this._lang = $('html').attr('lang');

        this._searchTickets = handler.bind(this);
        this._searchTicketsSuccess = success.bind(this);
        this._searchTicketsError = error.bind(this);

        this._routes = [];

        this._isRoundTrip = this.$page.find('input[name="is_round_trip"]').val();
        this._step = this.$page.find('input[name="step"]').val();

        this._firstLoad = false; // При первой загрузке страницы, нужна только для живого поиска новых билетов и сброса сессии

        // Если true, то будет поиск рейсов при открытии страницы. Нужен только для страницы с поиском, на остальных страницах просто открывается модуль поиска
        this._searchTrigger = search; 

        this._getRoutes = this._getRoutes.bind(this);
        this.updateRoutes = this.updateRoutes.bind(this);

        this._init();
    }

    async _init() {
        this._loader = new Loader();

        this._cityFrom = new CityFrom({
            searchModule: this.$searchModule,
            loader: this._loader,
            lang: this._lang,
            changeDatepickerHandler: this._changeDatepicker.bind(this),
        });

        this._cityTo = new CityTo({
            searchModule: this.$searchModule,
            loader: this._loader,
            lang: this._lang,
            changeDatepickerHandler: this._changeDatepicker.bind(this),
        });

        this._swap = new Swap({
            searchModule: this.$searchModule,
            cityFrom: this._cityFrom,
            cityTo: this._cityTo,
        })

        this._datePicker = new Datepicker({
            searchModule: this.$searchModule,
            lang: this._lang,
            cityFrom: this._cityFrom,
            cityTo: this._cityTo,
        });

        this._busReturnTicketSelector = new BusReturnTicketSelector({
            datepicker: this._datePicker,
        });

        this._passengers = new Passengers({
            searchModule: this.$searchModule,
        });

        this._searchTicketButton = new SearchTickets({
            searchModule: this.$searchModule,
            handler: this._searchTickets,
        });

        this._itemsWrapper = new ItemsWrapper({
            page: this.$page,
            handler: this._saveSeletedRoute.bind(this),
        });

        this._searchPopupTimer = new SearchPopupTimer({
            page: this.$page,
            handler: this._searchTickets,
        });

        this._filterDate = new FilterDate({
            page: this.$page,
            datepicker: this._datePicker,
            handler: this._searchTickets,
        });

        this._noRoutes = new NoRoutes({
            page: this.$page,
            lang: this._lang,
        });

        this._progress = new Progress({
            page: this.$page,
            lang: this._lang,
        });

        this._sort = new Sort({
            page: this.$page,
            itemsWrapper: this._itemsWrapper,
            updateRoutes: this.updateRoutes,
            getRoutes: this._getRoutes,
        });

        this._filters = new Filters({
            page: this.$page,
            updateRoutes: this.updateRoutes,
            getRoutes: this._getRoutes,
        });

        if (this._searchTrigger) {
            await this._cityFrom.getCities();
            await this._cityTo.getCities();
            this._searchTickets();
        } else {
            await this._cityFrom.getCities();
            await this._cityTo.getCities();
        }

    }

    _updateRoute(cityFrom, cityTo, startDate, endDate = null) {
        const passengers = this._passengers.getPassengersCount();
        let route = `${Util.getLocalization()}bus/search/`;

        const date = endDate ? `${startDate}_${endDate}` : startDate;
        this._step = this._firstLoad ? SearchStepTypes.DEPARTURE_STEP : this._step;

        route = `${route}${this._step}/${cityFrom.val.replace(' ', '-').toLowerCase()}/${cityTo.val.replace(' ', '-').toLowerCase()}/${cityFrom.id}/${cityTo.id}/${date}/${passengers[PassengersType.ADDULTS]}/${passengers[PassengersType.CHILDREN]}/`;

        window.history.pushState({}, null, route);
    }

    _saveSeletedRoute(event) {
        event.preventDefault();
        event.stopPropagation();

        const selectedItem = event.target;

        $('.bus_results_item').addClass('_block');

        const routeId = $(selectedItem).closest('.bus_results_item').data('routeid');
        const api = $(selectedItem).closest('.bus_results_item').data('api');
        if (!routeId || !api) return;

        let data = null;

        if (this._step === SearchStepTypes.DEPARTURE_STEP) {
            data = {
                route_id: routeId,
                city_from_id: this._cityFrom.getSelectedCity().id,
                city_from: this._cityFrom.getSelectedCity().text.replace(' ', '-'),
                city_to_id: this._cityTo.getSelectedCity().id,
                city_to: this._cityTo.getSelectedCity().text.replace(' ', '-'),
                apiName: api,
                passengers: this._passengers.getPassengers(),
                start_date: this._datePicker.getStartDate(),
                step: this._step,
            };
        } else {
            data = {
                route_id: routeId,
                city_from_id: this._cityTo.getSelectedCity().id,
                city_from: this._cityTo.getSelectedCity().text.replace(' ', '-'),
                city_to_id: this._cityFrom.getSelectedCity().id,
                city_to: this._cityFrom.getSelectedCity().text.replace(' ', '-'),
                apiName: api,
                passengers: this._passengers.getPassengers(),
                start_date: this._datePicker.getEndDate(),
                step: this._step,
            };
        }

        new AjaxBuilder({
            route: `${Util.getLocalization()}bus/add_cart/`,
            data,
            token: Util.token(),
        }, 'POST').execute({
            success: this._saveSelectedRouteSuccess.bind(this),
            error: this._saveSelectedRouteError.bind(this),
        });
    }

    _saveSelectedRouteSuccess(data) {
        const cityFrom = this._cityFrom.getSelectedCity();
        const cityTo = this._cityTo.getSelectedCity();
        const startDate = this._datePicker.getStartDate();
        const endDate = this._datePicker.getEndDate();
        const passengers = this._passengers.getPassengersCount();

        const date = endDate ? `${startDate}_${endDate}` : startDate;

        const route = `${Util.getLocalization()}bus/preorder/${this._step}/`;

        window.location = `${route}${cityFrom.val.replace(' ', '-').toLowerCase()}/${cityTo.val.replace(' ', '-').toLowerCase()}/${cityFrom.id}/${cityTo.id}/${date}/${passengers[PassengersType.ADDULTS]}/${passengers[PassengersType.CHILDREN]}/`;
    }

    _saveSelectedRouteError(data) {
        console.log('error');
    }

    _getRoutes() {
        return this._routes;
    }

    updateRoutes(routes) {
        this._routes = routes;
        return this;
    }

    _scrollToTop() {
        $('html, body').animate({ scrollTop: 0 }, 500);
    }

    _changeDatepicker() {
        const cityFrom = this._cityFrom.getSelectedCity();
        const cityTo = this._cityTo.getSelectedCity();

        if (!cityFrom || !cityTo ) return;

        this._datePicker.getCalendarPrices(cityFrom.id, cityTo.id);
    }
}
export default class AjaxBuilder {
    constructor({ route, data, token, bearer }, type = 'get') {
        this.route = route;
        this.data = data;
        this.type = type;
        this.token = token;
        this.bearer = bearer;
    }

    build({ success, error }) {
        return {
            ajax: {
                type: this.type,
                url: this.route,
                data: this.data,
                dataType: 'json',
                beforeSend: (xhr) => {
                    xhr.setRequestHeader("Authorization", 'Bearer '+ this.bearer);
                },
                success: success ? success : this.success,
                error: error ? error : this.error,
            },

            setup: {
                headers: this.token,
            }
        }
    }

    error() {
        return function (data) {
            console.log(data.responseJSON.errors ? data.responseJSON.errors : data.responseJSON.error);
        };
    }

    success() {
        return function () {
            document.location.reload();
        }
    }

    execute({ success, error }) {
        const { ajax, setup } = this.build({ success, error });
        $.ajaxSetup(setup);
        $.ajax(ajax);
    }

    async executeAsync({ success, error }) {
        const { ajax, setup } = this.build({ success, error });
        $.ajaxSetup(setup);
        await $.ajax(ajax);
    }
}
import Vocabulary from "../../../../Common/Vocabulary";

export class Progress {
    constructor({ page, lang }) {
        this.$page = page;
        this._lang = lang;

        this.$searchWrapper = $(this.$page).find('.loader-content');
        this.$progressBlock = $(this.$page).find('.loader__content');
        this.$progressCities = $(this.$progressBlock).find('.loader_city');
        this.$progressDate = $(this.$progressBlock).find('.loader_date');
    }

    show({ cityFrom, cityTo, date}) {
        this.$searchWrapper.show();
        $('body').css('overflow', 'hidden');
        
        if (cityFrom && cityTo) {
            $(this.$progressCities).html(`${cityFrom} - ${cityTo}`);
            $('.bus-search-page').find('.title_city').text(`${cityFrom} - ${cityTo}`);
        }

        if (date) {
            const dateArray = date.split('-');
            $(this.$progressDate).html(`${dateArray[2]} ${Vocabulary[this._lang].months[dateArray[1]]} ${dateArray[0]}`);
            $('.bus-search-page').find('.title_date').text(`${dateArray[2]} ${Vocabulary[this._lang].months[dateArray[1]]} ${dateArray[0]}`);
        }

    }

    hide() {
        this.$searchWrapper.hide();
        $('body').css('overflow', '');
    }
}
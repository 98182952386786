import { setGlobalDateI18n, parse, format } from 'fecha';

const DATEPICKER_MODE_MAP = {
    DEPARUTE: 'deparure',
    RETURN: 'return',
};

export class CustomDatepicker {
    constructor(options = {}) {
        this.format = options.format || "YYYY-MM-DD";
        this.separator = options.separator || " - ";
        this.startOfWeek = options.startOfWeek || "sunday";
        this.startDate = options.startDate || new Date();
        this.endDate = options.endDate || false;
        this.container = options.container || "";
        this.maxDays = options.maxDays || -1;
        this.maxAllowedDays = options.maxAllowedDays || -1;
        this.inline = options.inline || false;
        this.dateRangeSelectionMode = options.dateRangeSelectionMode || false;
        this.maxMonth = options.maxMonth ? options.maxMonth : 12;
        this.prices = options.prices ? options.prices : [];

        this.datepickerMode = DATEPICKER_MODE_MAP.DEPARUTE;

        this.inputFrom = options.inputFrom || $('#start-date');
        this.inputFromText = options.inputFromText || $('#js-start-text');
        this.inputTo = options.inputTo || $('#end-date');
        this.inputToText = options.inputToText || $('#js-end-text');

        this.popup_calendar = $('#popup_calendar');
        this.mobile_calendar = $('#mobile_calendar');
        this.mobileDateThereElement = this.mobile_calendar.find('#date-there');
        this.mobileDateBackElement = this.mobile_calendar.find('#date-back');

        this.currentScreenSize = $(window).width();
        this.mobileScreenSize = 868;

        this.months = [];

        this.disabledDates = options.disabledDates || [];
        this.disabledDaysOfWeek = options.disabledDaysOfWeek || [];

        //internationalization
        this.i18n = options.i18n || {
            "day-names-short": ["Вс", "Пт", "Вт", "Ср", "Чт", "Пт", "Сб"],
            "day-names": ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
            "month-names-short": ["Янв", "Февр", "Март", "Апр", "Май", "Июнь", "Июль", "Авг", "Сент", "Октб", "Нояб", "Дек"],
            "month-names": ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
            "there": 'Туда',
            "back": 'Обратно',
        };

        this.start = options.start ? this.parseDate(options.start).getTime() : false;
        this.end = options.end ? this.parseDate(options.end).getTime() : false;

        this._init();
    }

    _init() {
        // Дата начала, обычно текущий день
        if (this.startDate && typeof this.startDate === "string") {
            this.startDate = this.parseDate(this.startDate);
        }

        // Дата окончания (возможно не нужна)
        if (this.endDate && typeof this.endDate === "string") {
            this.endDate = this.parseDate(this.endDate);
        }

        this.redrawDatePicker();

        this.addListeners();
    }

    getDate(key) {
        let date = null;
        if (key === 'start') {
            date = this.getDateString(this.start);
        }

        if (key === 'end' && this.end) {
            date =  this.getDateString(this.end);
        }
        return date;
    }

    //Задать стартовую дату извне и перерисовать календари
    setStartDate(date) {
        this.start = date ? this.parseDate(date).getTime() : this.start;

        this.redrawDatePicker();
    }

    //Задать стартовую дату извне и перерисовать календари
    setEndDate(date) {
        this.end = date ? this.parseDate(date).getTime() : this.end;

        this.redrawDatePicker();
    }

    setCalendarPrices(prices) {
        this.prices = prices;
        this.redrawDatePicker();
    }

    //Перерисует календари
    redrawDatePicker() {
        // Получить дефолтное время, месяц вперед или назад
        let defaultTime = new Date();
        //Если дата инициализации будет выше текущей, то выберется текущая
        if (this.startDate && this.compareMonth(defaultTime, this.startDate) < 0) {
            defaultTime = new Date(this.startDate.getTime());
        }
        if (this.endDate && this.compareMonth(this.getNextMonth(defaultTime), this.endDate) > 0) {
            defaultTime = new Date(this.getPrevMonth(this.endDate.getTime()));
        }

        this.months = this.getMonthsAheadForYear(defaultTime);

        //Отрисовка мобильного календаря
        if (this.isTouchDevice()) {
            //Отрисовать все месяцы в мобильном календаре
            this.showMonths();
            //Показывать выбранные даты в календаре
            this.showSelectedDays();
            //Показать выбранную дату в инпуте
            this.setSelectedDateInInputs();
            //Показать выбранную дату в попапе на телефонах
            this.showSelectedDateInMobile();

            return true;
        }

        // Отрисовать два календаря
        this.showMonth(defaultTime, 1);
        this.showMonth(this.getNextMonth(defaultTime), 2);

        this.renderNextYearMonths(1);
        this.renderNextYearMonths(2);
        //Показывать/не показывать стрелки переключения месяцев
        this.shouldRenderNavigationButtons();
        //Показывать выбранные даты в календаре
        this.showSelectedDays();
        //Показать выбранную дату в инпуте
        this.setSelectedDateInInputs();
    }

    getMonthsAheadForYear(date) {
        const currentMonth = date.getMonth();
        let currentYear = date.getFullYear();

        const months = [];

        for (var i = 0; i < this.maxMonth; i++) {
            const newMonth = (currentMonth + i) % 12;

            months.push({
                id: i,
                monthName: this.getMonthName(newMonth),
                month: newMonth,
                year: currentYear,
                date: this.parseDate(`${currentYear}-${this.parseMonth(newMonth)}-01`),
            });

            if (newMonth === 11) {
                currentYear++;
            }
        }

        return months;
    }

    renderNextYearMonths(month) {
        const currentDate = this[`month${month}`];
        const monthListHtml = $(`#month-${month} .calendar_month_list`);
        monthListHtml.html('');

        this.months.forEach((month, index) => {
            if (index === 0 || month.month === 0) {
                monthListHtml.append(`<div class="_year">${month.year}</div>`);
            }

            monthListHtml.append(`<div class="_month ${currentDate.getMonth() === month.month && currentDate.getFullYear() === month.year ? '_selected' : ''}" data-index="${month.id}">${month.monthName}</div>`);
        });

        return true;
    }

    addListeners() {
        this.inputFrom.on('click', this.openForwardCalendar.bind(this));
        this.inputTo.on('click', this.openBackwardCalendar.bind(this));

        if (this.isTouchDevice()) {
            this.mobile_calendar.find('.mobile_calendar_head').on('click', this.closeDatepicker.bind(this));

            this.mobile_calendar.find('#mobile_calendar_body').on('click', this.handleMobileDateSelection.bind(this));
        } else {
            $(document).on('click', '.calendar_month_title', function () {
                if ($(this).closest('.calendar_month').hasClass('_active')) {
                    $(this).closest('.calendar_month').removeClass('_active');
                } else {
                    $(this).closest('.calendar_month').addClass('_active');
                }
            });
    
            $(document).mouseup(function (e) {
                let calendar_month = $('.calendar_month._active');
    
                if (!calendar_month.is(e.target) && calendar_month.has(e.target).length === 0) {
                    $('.calendar_month._active').removeClass('_active');
                }
            });
    
            this._nextMonthBtn = $('.popup_calendar_main .btn_nav._next');
            this._nextMonthBtn.on('click', this.goToNextMonth.bind(this));
    
            this._prevMonthBtn = $('.popup_calendar_main .btn_nav._prev');
            this._prevMonthBtn.on('click', this.goToPrevMonth.bind(this));
    
            this._monthList = $('.calendar_month_list');
            this._monthList.on('click', this.switchToMonth.bind(this));
    
            this.returnBtnElement = this.popup_calendar.find('button#return_ticket');
            this.returnBtnElement.on('click', this.handleNoReturnTicket.bind(this));
    
            if (this.dateRangeSelectionMode) {
                this.popup_calendar.on("mouseover", this.datepickerHover.bind(this));
            }
    
            $(document).on('click', this.documentClick.bind(this));
        }
    }

    showDatepicker(e) {
        this.isTouchDevice() ? this.mobile_calendar.addClass('_active') : this.popup_calendar.addClass('_active');

        return true;
    }

    openForwardCalendar(e) {
        if (e) e.preventDefault();
        this.datepickerMode = DATEPICKER_MODE_MAP.DEPARUTE;
        this.isTouchDevice() ? this.mobile_calendar.addClass('_active') : this.popup_calendar.addClass('_active');
        return true;
    }

    openBackwardCalendar(e) {
        if (e) e.preventDefault();
        this.datepickerMode = DATEPICKER_MODE_MAP.RETURN;
        this.isTouchDevice() ? this.mobile_calendar.addClass('_active') : this.popup_calendar.addClass('_active');

        return true;
    }

    closeDatepicker() {
        this.isTouchDevice() ? this.mobile_calendar.removeClass('_active') : this.popup_calendar.removeClass('_active');

        return true;
    }

    handleNoReturnTicket() {
        this.endDate = false;
        this.end = false;
        this.redrawDatePicker();

        this.closeDatepicker();
    }

    datepickerHover(e) {
        const selectedElement = $(e.target);
        if (selectedElement.hasClass('_date') || selectedElement.closest('._date').length > 0) {
            const dayElement = selectedElement.hasClass('_date') ? $(e.target) : selectedElement.closest('._date');
            this.dayHovering(dayElement);
        }
    }
    //Ховер для дней недели
    dayHovering(dayElement) {
        const hoverTime = parseInt(dayElement.data("time"), 10);

        if (!dayElement.hasClass('_invalid')) {
            const days = this.popup_calendar.find('._date');

            days.each((index, day) => {
                const time = parseInt($(day).data("time"), 10);
                if (time === hoverTime) {
                    $(day).addClass('_hovering');
                } else {
                    $(day).removeClass('_hovering');
                }

                if (this.dateRangeSelectionMode && this.start && !this.end && (this.start < time && hoverTime >= time || this.start > time && hoverTime <= time)) {
                    $(day).addClass('_hovering');
                } else {
                    $(day).removeClass('_hovering');
                }
            });
        }
    }
    //Почистить ховер
    clearHovering() {
        // Remove hovering class from every day
        const days = this.popup_calendar.find('_date');

        days.each((index, item) => {
            $(item).removeClass('_hovering');
        });
    }
    //Нажатие по странице, закрыть календарь или выбрать дату
    documentClick(e) {
        const selectedElement = $(e.target);
        if (selectedElement[0] !== this.inputFrom[0] && selectedElement[0] !== this.inputTo[0] && !document.getElementById('popup_calendar').contains(e.target)) {
            this.closeDatepicker();
        } else if (selectedElement.hasClass('_date') || selectedElement.closest('._date').length > 0) {
            const dayElement = selectedElement.hasClass('_date') ? $(e.target) : selectedElement.closest('._date');
            this.dayClicked(dayElement);

            if (!this.dateRangeSelectionMode) {
                this.closeDatepicker();
            }
        }
    }
    //Выбор даты на планшетах/телефонах
    handleMobileDateSelection(e) {
        const selectedElement = $(e.target);
        if (!selectedElement.hasClass('_date') && selectedElement.closest('._date').length < 1)  return false;
        
        const dayElement = selectedElement.hasClass('_date') ? $(e.target) : selectedElement.closest('._date');
        this.dayClicked(dayElement);

        if (!this.dateRangeSelectionMode) {
            this.closeDatepicker();
        }
    }
    //Выбор даты
    dayClicked(dayElement) {
        if (dayElement.hasClass('_invalid')) {
            return false;
        }

        const isSelectStart = this.start && this.end || !this.start && !this.end;

        const time = parseInt(dayElement.data('time'), 10);

        if (!this.dateRangeSelectionMode) {
            this.start = time;
            this.end = false;
        } else if ((isSelectStart || this.datepickerMode === DATEPICKER_MODE_MAP.DEPARUTE) && this.datepickerMode !== DATEPICKER_MODE_MAP.RETURN) {
            this.start = time;
            this.end = false;
        } else if (this.start) {
            this.end = time;
        }

        this.datepickerMode = null;

        // Swap dates if they are inverted
        if (this.start && this.end && this.start > this.end) {
            const tmp = this.end;
            this.end = this.start;
            this.start = tmp;
        }

        this.start = parseInt(this.start, 10);
        this.end = parseInt(this.end, 10);

        this.clearHovering();

        if (this.start && !this.end) {
            this.dayHovering(dayElement);
        }

        this.updateSelectableRange();

        if (this.start && this.end) {
            this.checkAndSetDayClasses();
        }

        this.showSelectedDays();
        this.setSelectedDateInInputs();
        this.showSelectedDateInMobile();
    }

    showSelectedDays() {
        if (!this.start && !this.end) {
            return false;
        }

        const days = this.isTouchDevice() ? this.mobile_calendar.find('._date') : this.popup_calendar.find("._date");

        days.each((index, item) => {
            const time = parseInt($(item).data("time"), 10);

            // Добавить класс для выбранного элемента
            if (this.start && this.end && this.end >= time && this.start <= time || this.start && !this.end && this.getDateString(this.start, "YYYY-MM-DD") === this.getDateString(time, "YYYY-MM-DD")) {
                $(item).addClass('_selected _hover');
            } else {
                $(item).removeClass('_selected _hover');
            }

            // Добавить класс к первому дню диапазона.
            if (this.start && this.getDateString(this.start, "YYYY-MM-DD") === this.getDateString(time, "YYYY-MM-DD")) {
                $(item).addClass('_first-day-selected');
            } else {
                $(item).removeClass('_first-day-selected');
            }

            // Добавить класс к последнему дню диапазона
            if (this.end && this.getDateString(this.end, "YYYY-MM-DD") === this.getDateString(time, "YYYY-MM-DD")) {
                $(item).addClass('_last-day-selected');
            } else {
                $(item).removeClass('_last-day-selected');
            }
        });

        return true;
    }

    setSelectedDateInInputs() {
        const startDate = new Date(this.start);
        const endDate = this.end && this.dateRangeSelectionMode ? new Date(this.end) : null;
        
        this.inputFrom.val(this.getDateString(startDate));
        this.inputFromText.html(`${startDate.getDate()} ${this.getAbbreviatedMonthName(startDate.getMonth())}, ${this.getDayName(startDate.getDay())}`.toLowerCase());

        if (endDate) {
            this.inputTo.val(this.getDateString(endDate));
            this.inputToText.html(`${endDate.getDate()} ${this.getAbbreviatedMonthName(endDate.getMonth())}, ${this.getDayName(endDate.getDay())}`.toLowerCase());
        }

        if (!endDate) {
            this.inputTo.val('');
            this.inputToText.html(this.i18n['back']);
        }
    }

    showSelectedDateInMobile() {
        const startDate = new Date(this.start);
        const endDate = this.end && this.dateRangeSelectionMode ? new Date(this.end) : null;

        this.mobileDateThereElement.html(`<span>${this.i18n['there']} -</span> ${startDate.getDate()} ${this.getAbbreviatedMonthName(startDate.getMonth())}, ${this.getDayName(startDate.getDay())}`);

        if (endDate) {
            this.mobileDateBackElement.html(`<span>${this.i18n['back']} -</span> ${endDate.getDate()} ${this.getAbbreviatedMonthName(endDate.getMonth())}, ${this.getDayName(endDate.getDay())}`);
        }
    }

    checkAndSetDayClasses() {
        const days = this.popup_calendar.find("._date");

        days.each((index, item) => {
            const time = parseInt($(item).data("time"), 10);
            const day = new Date(time);
            const daytype = $(item).data('type');
            let valid;

            valid = this.isValidDate(day.getTime());

            if (this.startDate && this.compareDay(day, this.startDate) < 0 || this.endDate && this.compareDay(day, this.endDate) > 0) {
                valid = false;
            }

            const _day = {
                date: day,
                type: daytype,
                day: day.getDate(),
                time,
                valid
            };

            const classes = this.getDayClasses(_day);

            $(item).addClass(classes.join(' '));
        });
    }

    updateSelectableRange() {
        const days = this.popup_calendar.find("._date");
        const isSelecting = this.start && !this.end;

        days.each((index, day) => {
            if (isSelecting) {
                if ($(day).hasClass('visibleMonth') && ($(day).hasClass('_valid') || $(day).hasClass('_disabled') || $(day).hasClass('_before-disabled-date'))) {
                    const time = parseInt($(day).data("time"), 10);
                    if (this.isValidDate(time)) {
                        $(day).addClass('_valid').removeClass('_invalid _disabled');
                    } else {
                        $(day).addClass('_invalid').removeClass('_valid');
                    }
                }
            } else if ($(day).hasClass('_enabled') && $(day).hasClass('_before-disabled-date')) {
                $(day).addClass('_invalid').removeClass('_valid');
                if (!$(day).hasClass('_before-disabled-date')) {
                    $(day).addClass('_disabled');
                }
            }
        });

        return true;
    }

    //Переключить на следующий месяц
    goToNextMonth(e) {
        const month1 = this.month2;
        const month2 = this.getNextMonth(this.month2);
        this.showMonth(month1, 1);
        this.showMonth(month2, 2);

        this.shouldRenderNavigationButtons();
        this.switchActiveMonth();
        this.showSelectedDays();
    }
    //Переключить на предедущий месяц
    goToPrevMonth(e) {
        const month1 = this.getPrevMonth(this.month1);
        const month2 = this.month1;
        this.showMonth(month1, 1);
        this.showMonth(month2, 2);

        this.shouldRenderNavigationButtons();
        this.switchActiveMonth();
        this.showSelectedDays();
    }

    switchActiveMonth() {
        const monthDatepicker1 = $('#month-1');
        const monthDatepicker2 = $('#month-2');

        const selectedMonth1 = this.months.find(month => this.month1.getFullYear() === month.year && this.month1.getMonth() === month.month);
        const selectedMonth2 = this.months.find(month => this.month2.getFullYear() === month.year && this.month2.getMonth() === month.month);

        $('.calendar_month_list ._month._selected').removeClass('_selected');

        monthDatepicker1.find(`.calendar_month_list ._month[data-index="${selectedMonth1.id}"]`).addClass('_selected');
        monthDatepicker2.find(`.calendar_month_list ._month[data-index="${selectedMonth2.id}"]`).addClass('_selected');
    }

    switchToMonth(e) {
        const selectedMonthElement = $(e.target);

        if (selectedMonthElement.hasClass('_selected') || !selectedMonthElement.hasClass('_month')) {
            return true;
        }

        const selectedMonth = this.months[selectedMonthElement.data('index')];
        const datepickerNumber = selectedMonthElement.closest('.popup_calendar_col').data('datepicker');

        const monthComparisonResult = datepickerNumber === 1 ? this.compareMonth(selectedMonth.date, this.months[this.months.length - 1].date) : this.compareMonth(selectedMonth.date, this.months[0].date);
        //Если выбран первый календарь и дата меньше чем диапазон месяцев на год
        if (datepickerNumber === 1 && monthComparisonResult === -1) {
            this.showMonth(selectedMonth.date, 1);
            this.showMonth(this.getNextMonth(selectedMonth.date), 2);
        }
        //Если выбран первый календарь и дата равна последней в разрешенной
        if (datepickerNumber === 1 && monthComparisonResult === 0) {
            this.showMonth(this.getPrevMonth(selectedMonth.date), 1);
            this.showMonth(selectedMonth.date, 2);
        }
        //Если выбран второй календарь и дата больше чем текущий месяц
        if (datepickerNumber === 2 && monthComparisonResult === 1) {
            this.showMonth(this.getPrevMonth(selectedMonth.date), 1);
            this.showMonth(selectedMonth.date, 2);
        }
        //Если выбран второй календарь и дата равна текущему месяцу
        if (datepickerNumber === 2 && monthComparisonResult === 0) {
            this.showMonth(selectedMonth.date, 1);
            this.showMonth(this.getNextMonth(selectedMonth.date), 2);
        }

        this.shouldRenderNavigationButtons();
        this.switchActiveMonth();
        this.showSelectedDays();

        $('.calendar_month._active').removeClass('_active');
    }

    shouldRenderNavigationButtons() {
        if (this.compareMonth(this.month1, this.months[0].date) === 0) {
            $('.popup_calendar_main .btn_nav._prev').addClass('_hide');
        } else {
            $('.popup_calendar_main .btn_nav._prev').removeClass('_hide');
        }

        if (this.compareMonth(this.month2, this.months[this.months.length - 1].date) === 0) {
            $('.popup_calendar_main .btn_nav._next').addClass('_hide');
        } else {
            $('.popup_calendar_main .btn_nav._next').removeClass('_hide');
        }
    }

    showMonth(date, month) {
        date.setHours(0, 0, 0, 0);

        // Получить название месяца и элемент календаря
        const name = this.getMonthName(date.getMonth());
        const monthDom = this.getMonthDom(month);

        monthDom.find('[data-month-date]').text(name);
        monthDom.find('[data-year-date]').text(date.getFullYear());

        //Удалить все старые даты
        this.removeMonthElements(monthDom);

        //Создать элементы даты для календаря
        this.createMonthDomString(date, monthDom);

        //Сохранить выбранный месяц
        this["month" + month] = date;
    }

    showMonths() {
        this.removeMonthsInMobile();
        this.month1 = this.months[0].date;
        this.month2 = this.months[1].date;
        
        this.months.forEach(month => {
            this.createMonthDomStringMobile(month);
        });
    }

    createMonthDomString(date, monthDom) {
        const days = [];
        let valid;

        date.setDate(1);

        let dayOfWeek = date.getDay();
        const currentMonth = date.getMonth();

        if (dayOfWeek === 0 && this.startOfWeek === "monday") {
            dayOfWeek = 7;
        }

        //Если месяц начинается не с понедельника, то тут скрипт получит дни до начала месяца
        if (dayOfWeek > 0) {
            for (let i = dayOfWeek; i > 0; i--) {
                const day = new Date(date.getTime() - 86400000 * i);
                valid = this.isValidDate(day.getTime());
                if (this.startDate && this.compareDay(day, this.startDate) < 0 || this.endDate && this.compareDay(day, this.endDate) > 0) {
                    valid = false;
                }

                days.push({
                    date: day,
                    type: "lastMonth",
                    day: day.getDate(),
                    time: day.getTime(),
                    valid
                });
            }
        }

        //Это сгенерит даты в месяце + оставшиеся даты в конце недели
        for (let i = 0; i < 42; i++) {
            const day = this.addDays(date, i);

            valid = this.isValidDate(day.getTime());
            if (this.startDate && this.compareDay(day, this.startDate) < 0 || this.endDate && this.compareDay(day, this.endDate) > 0) {
                valid = false;
            }

            days.push({
                date: day,
                type: day.getMonth() === currentMonth ? "visibleMonth" : "nextMonth",
                day: day.getDate(),
                time: day.getTime(),
                valid
            });
        }
        //Созданее неделей в календаре (всего 6)
        for (let week = 0; week < 6; week++) {
            const weekHtmlString = $('<div class="calendar_body_row calendar_body_dates"></div');

            //Созданее дней недели
            for (let i = 0; i < 7; i++) {
                let day = this.startOfWeek === "monday" ? i + 1 : i;
                day = days[week * 7 + day];
                const classes = this.getDayClasses(day);

                let price = '.';

                if (Object.keys(this.prices).length !== 0) {
                    const date = this.getDateString(day.date);
                    price = date in this.prices && this.prices[date].price > 0 ? Math.round(Number(this.prices[date].price)) : '.';
                }

                weekHtmlString.append(`<div class="${classes.join(" ")}" data-type="${day.type}" data-time="${day.time}"><div class="_day">${day.day}</div><div class="_price">${price}</div></div>`);
            }

            this.createMonthElements(monthDom, weekHtmlString);
        }

        return true;
    }
    //TODO::Посмотреть схожий метод по отрисовке одного календаря createMonthDomString и вынести смежный функционал в отдельные методы
    createMonthDomStringMobile(month) {
        const days = [];
        let valid;

        let dayOfWeek = month.date.getDay();
        const currentMonth = month.date.getMonth();

        if (dayOfWeek === 0 && this.startOfWeek === "monday") {
            dayOfWeek = 7;
        }

        //Если месяц начинается не с понедельника, то тут скрипт получит дни до начала месяца
        if (dayOfWeek > 0) {
            for (let i = dayOfWeek; i > 0; i--) {
                const day = new Date(month.date.getTime() - 86400000 * i);
                valid = this.isValidDate(day.getTime());
                if (this.startDate && this.compareDay(day, this.startDate) < 0 || this.endDate && this.compareDay(day, this.endDate) > 0) {
                    valid = false;
                }

                days.push({
                    date: day,
                    type: "lastMonth",
                    day: day.getDate(),
                    time: day.getTime(),
                    valid
                });
            }
        }

        //Это сгенерит даты в месяце + оставшиеся даты в конце недели
        for (let i = 0; i < 42; i++) {
            const day = this.addDays(month.date, i);

            valid = this.isValidDate(day.getTime());
            if (this.startDate && this.compareDay(day, this.startDate) < 0 || this.endDate && this.compareDay(day, this.endDate) > 0) {
                valid = false;
            }

            days.push({
                date: day,
                type: day.getMonth() === currentMonth ? "visibleMonth" : "nextMonth",
                day: day.getDate(),
                time: day.getTime(),
                valid
            });
        }

        //Создание самого месяца
        const monthHtmlString = $(`
            <div class="mobile_calendar_body">
                <div class="mobile_calendar_month_title">
                    <div>${month.monthName}</div>
                    <div>${month.year}</div>
                </div>
            </div>
        `);

        //Созданее неделей в календаре (всего 6)
        for (let week = 0; week < 6; week++) {
            const weekHtmlString = $('<div class="mobile_calendar_row mobile_calendar_row_dates"></div');

            //Созданее дней недели
            for (let i = 0; i < 7; i++) {
                let day = this.startOfWeek === "monday" ? i + 1 : i;
                day = days[week * 7 + day];
                const classes = this.getDayClasses(day);

                let price = '.';

                if (Object.keys(this.prices).length !== 0) {
                    const date = this.getDateString(day.date);
                    price = date in this.prices && this.prices[date].price > 0 ? this.prices[date].price : '.';
                }

                weekHtmlString.append(`<div class="${classes.join(" ")}" data-type="${day.type}" data-time="${day.time}"><div class="_day">${day.day}</div><div class="_price">${price}</div></div>`);
            }

            monthHtmlString.append(weekHtmlString);
        }

        this.mobile_calendar.find('#mobile_calendar_body').append(monthHtmlString);

        return true;

    }

    removeMonthsInMobile() {
        this.mobile_calendar.find('#mobile_calendar_body .mobile_calendar_body').remove();
    }

    removeMonthElements(element) {
        element.find('.calendar_body_dates').remove();
    }

    createMonthElements(element, htmlString) {
        element.find('.calendar_body').append(htmlString);
    }


    getMonthName(month) {
        return this.lang("month-names")[month];
    }

    getAbbreviatedMonthName(month) {
        return this.lang("month-names-short")[month];
    }

    getDayName(day) {
        return this.lang("day-names-short")[day];
    }

    getMonthDom(month) {
        return $(`#month-${month}`);
    }

    parseDate(date) {
        this.setFechaI18n();
        return parse(date, this.format);
    }
    //Формат локализации
    setFechaI18n() {
        setGlobalDateI18n({
            dayNamesShort: this.i18n["day-names-short"],
            dayNames: this.i18n["day-names"],
            monthNamesShort: this.i18n["month-names-short"],
            monthNames: this.i18n["month-names"]
        });
    }
    //Проверить что за устройуство
    isTouchDevice() {
        return this.mobileScreenSize >= this.currentScreenSize;
    }
    //Следующий месяц
    getNextMonth(month) {
        const _m = new Date(month.valueOf());
        return new Date(_m.setMonth(_m.getMonth() + 1, 1));
    }
    //Предедущий месяц
    getPrevMonth(month) {
        const _m = new Date(month.valueOf());
        return new Date(_m.setMonth(_m.getMonth() - 1, 1));
    }

    // Cравнить два месяца: проверить, является ли month1 ранее/позже/тем же месяцем, что и month2.
    compareMonth(month1, month2) {
        const p = parseInt(this.getDateString(month1, "YYYYMM"), 10) - parseInt(this.getDateString(month2, "YYYYMM"), 10);
        //Месяц1 больше чем месяц2
        if (p > 0) {
            return 1;
        }
        //Месяцы равны
        if (p === 0) {
            return 0;
        }
        //Месяц1 меньше чем месяц2
        return -1;
    }

    //Сравнить два дня: проверить, является ли day1 ранее/позже/тем же днем, что и day2.
    compareDay(day1, day2) {
        const p = parseInt(this.getDateString(day1, "YYYYMMDD"), 10) - parseInt(this.getDateString(day2, "YYYYMMDD"), 10);
        if (p > 0) {
            return 1;
        }
        if (p === 0) {
            return 0;
        }
        return -1;
    }

    parseMonth(month) {
        if (month < 9) {
            month = `0${month + 1}`;
        } else {
            month = month + 1;
        }

        return month;
    }

    //Получить дату в нужном формате
    getDateString(date) {
        let formatDate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.format;

        this.setFechaI18n();

        return format(date, formatDate);
    }

    lang(s) {
        return s in this.i18n ? this.i18n[s] : "";
    }

    isValidDate(time) {
        time = parseInt(time, 10);
        //Проверить действительная ли дата
        if (this.startDate && this.compareDay(time, this.startDate) < 0 || this.endDate && this.compareDay(time, this.endDate) > 0) {
            return false;
        }

        if (this.startDate && this.maxDays > 0 && this.countDays(time, this.startDate) > this.maxDays) {
            return false;
        }

        if (this.start && !this.end) {
            if (this.dateRangeSelectionMode && this.maxAllowedDays > 0 && this.countDays(time, this.start) > this.maxAllowedDays) {
                return false;
            }

            if (this.selectForward && time < this.start) {
                return false;
            }

            if (this.disabledDates.length > 0) {
                const limit = this.getClosestDisabledDates(new Date(parseInt(this.start, 10)));
                if (limit[0] && this.compareDay(time, limit[0]) <= 0) {
                    return false;
                }
                if (limit[1] && this.compareDay(time, limit[1]) >= 0) {
                    return false;
                }
            }

            if (this.disabledDaysOfWeek.length > 0) {
                const limit = this.getClosestDisabledDays(new Date(parseInt(this.start, 10)));
                if (limit[0] && this.compareDay(time, limit[0]) <= 0) {
                    return false;
                }
                if (limit[1] && this.compareDay(time, limit[1]) >= 0) {
                    return false;
                }
            }
        }
        return true;
    }

    addDays(date, days) {
        const result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    countDays(start, end) {
        return Math.abs(this.daysFrom1970(start) - this.daysFrom1970(end)) + 1;
    }

    daysFrom1970(t) {
        return Math.round(this.toLocalTimestamp(t) / 86400000);
    }

    toLocalTimestamp(t) {
        if (typeof t === "object" && t.getTime) {
            t = t.getTime();
        }
        if (typeof t === "string" && !t.match(/\d{13}/)) {
            t = this.parseDate(t).getTime();
        }
        t = parseInt(t, 10) - new Date().getTimezoneOffset() * 60 * 1000;
        return t;
    }

    getClosestDisabledDates(x) {
        let dates = [false, false];

        if (x < this.disabledDatesTime[0]) {
            if (this.enableCheckout) {
                dates = [false, this.addDays(this.disabledDatesTime[0], 1)];
            } else {
                dates = [false, this.disabledDatesTime[0]];
            }

        } else if (x > this.disabledDatesTime[this.disabledDatesTime.length - 1]) {
            dates = [this.disabledDatesTime[this.disabledDatesTime.length - 1], false];
        } else {
            let bestPrevDate = this.disabledDatesTime.length;
            let bestNextDate = this.disabledDatesTime.length;
            const maxDateValue = Math.abs(new Date(0, 0, 0).valueOf());
            let bestPrevDiff = maxDateValue;
            let bestNextDiff = -maxDateValue;
            let currDiff = 0;
            let i;
            for (i = 0; i < this.disabledDatesTime.length; ++i) {
                currDiff = x - this.disabledDatesTime[i];
                if (currDiff < 0 && currDiff > bestNextDiff) {
                    bestNextDate = i;
                    bestNextDiff = currDiff;
                }
                if (currDiff > 0 && currDiff < bestPrevDiff) {
                    bestPrevDate = i;
                    bestPrevDiff = currDiff;
                }
            }
            if (this.disabledDatesTime[bestPrevDate]) {
                dates[0] = this.disabledDatesTime[bestPrevDate];
            }
            if (typeof this.disabledDatesTime[bestPrevDate] === "undefined") {
                dates[1] = false;
            } else if (this.enableCheckout) {
                dates[1] = this.addDays(this.disabledDatesTime[bestNextDate], 1);
            } else {
                dates[1] = this.disabledDatesTime[bestNextDate];
            }
        }
        return dates;
    }

    getClosestDisabledDays(day) {
        const dates = [false, false];
        for (let i = 0; i < 7; i++) {
            const _date = this.substractDays(day, i);
            if (this.disabledDaysIndexes.indexOf(parseInt(fecha__namespace.format(_date, "d"), 10)) > -1) {
                dates[0] = _date;
                break;
            }
        }
        for (let i = 0; i < 7; i++) {
            const _date = this.addDays(day, i);
            if (this.disabledDaysIndexes.indexOf(parseInt(fecha__namespace.format(_date, "d"), 10)) > -1) {
                dates[1] = _date;
                break;
            }
        }
        return dates;
    }

    getDayClasses(day) {
        const isToday = this.getDateString(day.time) === this.getDateString(new Date());
        const isStartDate = this.getDateString(day.time) === this.getDateString(this.startDate);
        let isDisabled = false;
        let isDayOfWeekDisabled = false;
        let isFirstEnabledDate = false;

        let isDayBeforeDisabledDate = false;

        if (day.valid || day.type === "visibleMonth") {
            const dateString = this.getDateString(day.time, "YYYY-MM-DD");
            if (this.disabledDates.length > 0) {
                const limit = this.getClosestDisabledDates(day.date);

                if (limit[0] === false) {
                    limit[0] = this.substractDays(this.startDate, 1);
                }
                if (limit[0] && limit[1]) {
                    if (this.compareDay(day.date, limit[0]) && this.countDays(limit[0], limit[1]) - 2 > 0) {
                        const daysBeforeNextDisabledDate = this.countDays(limit[1], day.date) - 1;
                        const daysAfterPrevDisabledDate = this.countDays(day.date, limit[0]) - 1;
                        if (this.selectForward && daysBeforeNextDisabledDate < this.minDays) {
                            day.valid = false;
                        } else if (!this.selectForward && daysBeforeNextDisabledDate < this.minDays && daysAfterPrevDisabledDate < this.minDays) {
                            day.valid = false;
                        }
                        if (!day.valid && this.enableCheckout && daysBeforeNextDisabledDate === 2) {
                            isDayBeforeDisabledDate = true;
                        }
                    }
                }
                if (this.disabledDates.indexOf(dateString) > -1) {
                    day.valid = false;
                    isDisabled = true;
                    this.isFirstDisabledDate++;

                    this.lastDisabledDate = day.date;
                } else {
                    this.isFirstDisabledDate = 0;
                }

                if (day.valid && this.lastDisabledDate && this.compareDay(day.date, this.lastDisabledDate) > 0 && this.countDays(day.date, this.lastDisabledDate) === 2) {
                    isFirstEnabledDate = true;
                }
            }
            if (this.disabledDaysOfWeek.length > 0) {
                if (this.disabledDaysOfWeek.indexOf(format(day.time, "dddd")) > -1) {
                    day.valid = false;
                    isDayOfWeekDisabled = true;
                }
            }
        }
        const classes = [
            "_date",
            day.type,
            day.valid ? "_valid" : "_invalid",
            isToday ? "_bordered" : "",
            isDisabled ? "_disabled" : "",
            isDayBeforeDisabledDate ? "_before-disabled-date" : "",
            isStartDate || isFirstEnabledDate ? "_checkin-only" : "",
            isDayOfWeekDisabled ? "_week-disabled" : ""];
        return classes;
    }

    getFirstDayOfMonth(monthNumber) {
        return this.getDateString(this[`month${monthNumber}`]);
    }
}

import AjaxBuilder from '../../../../Common/AjaxBuilder';
import { Util } from '../../../../Common/Util';
import Vocabulary from "../../../../Common/Vocabulary";

export class City {
    constructor({ module, lang, changeDatepickerHandler }) {
        this.$searchModule = module;
        this._lang = lang;
        this._changeDatepickerHandler = changeDatepickerHandler;

        this._searchCityRoute = `${Util.getLocalization()}bus/api/search_city`;
        this._searchPopularCitiesRoute = `${Util.getLocalization()}bus/api/get_popular_cities`;

        //TODO::поправить
        $(document).mouseup(function (e) {
            let open_loc_from = $('.widget_input__from .widget_locations._show'),
                open_loc_from_input = $('.widget_input__from input');
            if (!open_loc_from.is(e.target) && open_loc_from.has(e.target).length === 0
                && !open_loc_from_input.is(e.target)) {
                const selectedItem = open_loc_from.find('._selected');
                if (open_loc_from.length > 0 && selectedItem.length < 1) {
                    open_loc_from_input.val('');
                }

                $('.widget_input__from .widget_locations._show').removeClass('_show');
            }

            let open_loc_to = $('.widget_input__to .widget_locations._show'),
                open_loc_to_input = $('.widget_input__to input');
            if (!open_loc_to.is(e.target) && open_loc_to.has(e.target).length === 0
                && !open_loc_to_input.is(e.target)) {
                const selectedItem = open_loc_to.find('._selected');
                if (open_loc_to.length > 0 && selectedItem.length < 1) {
                    open_loc_to_input.val('');
                }
                $('.widget_input__to .widget_locations._show').removeClass('_show');
            }


            let open_passengers = $('.passengers_select._show');
            if (!open_passengers.is(e.target) && open_passengers.has(e.target).length === 0) {
                $('.passengers_select._show').removeClass('_show');
            }

        });
    }

    getSelectedCity() {
        if (this._cities.length > 0 && this._selectedCityElement) {
            const id = this._selectedCityElement.data('id');
            let city = this._cities.find(city => city.id === id);

            return {
                id: city.id,
                val: city.value,
                text: city.city_name,
            };
        }
    }

    //Это 3 метода нужны для свапа городов местами (getAllCities, setAllCities, setSelectedElement)
    //Получить все найденные города
    getAllCities() {
        return this._cities;
    }
    // Добавить найденные города
    setAllCities(cities) {
        this._cities = cities;
        this._createCityList();
    }
    // Добавить элемент выбранного города из списка
    setSelectedElement(city) {
        this._selectedCityElement = this.$locationWrapper.find(`.city_list .city_item[data-id="${city.id}"]`);
        $(this._selectedCityElement).addClass('_selected');
        this.$inputElement.val($(this._selectedCityElement).data('name'));
    }

    _takeSelectedCity(type) {
        return this.$searchModule.find(`input[name="${type}"]`).val();
    }

    _getPopularCities(type) {
        new AjaxBuilder({
            route: this._searchPopularCitiesRoute,
            data: {
                type: type,
            }
        }, 'GET').execute({
            success: (data) => {
                this._cities = data.cities;
                this._popularCities = data.cities;

                this._createCityList('popular');
            }
        })
    }

    //Поиск городов в базе
    _searchCityHandler(event) {
        const searchedCity = this.$inputElement.val();

        $(this.$locationWrapper).addClass('_show');
        this._showLoader();

        clearTimeout(this._timer);
        this._timer = setTimeout(() => {
            new AjaxBuilder({
                route: this._searchCityRoute,
                data: {
                    city: searchedCity,
                },
            }, 'GET').execute({
                success: this._searchCitySuccess.bind(this),
                error: this._searchCityError.bind(this),
            });
        }, 1000);
    }

    //Успешный поиск городов в базе
    _searchCitySuccess(data) {
        if (data.cities.length > 0) {
            // this._cities = data.cities.sort((a, b) => a.value.localeCompare(b.value));
            this._cities = data.cities;

            this._createCityList();
            return;
        }
        this.$locationWrapper.html(`
            <div class="widget_locations_title">${Vocabulary[this._lang].cities_not_found}</div>
        `);
    }

    //Поиск городов в базе с ошибкой
    _searchCityError(data) {
        this.$locationWrapper.html(`
            <div class="widget_locations_title">${Vocabulary[this._lang].cities_not_found}</div>
        `);
    }

    //Создает список из найденных городов
    _createCityList(type = 'default') {
        let popularTitle = '';

        if (type === 'popular') {
            popularTitle = `<div class="widget_locations_title">Популярні напрямки</div>`;
        }

        const list = this._cities.map(city => {
            return `<li class="city_item" data-id="${city.id}" data-name="${city.city_name}" data-value="${city.value}">${city.text}</li>`;
        }).join('');

        this.$locationWrapper.html(`
            ${popularTitle}
            <ul class="city_list">${list}</ul>
        `);
    }

    //Сам обработчик выбора города из списка
    _cityHandler(event) {
        event.preventDefault();
        if ($(event.target).hasClass('city_item') || $(event.target).closest('li').hasClass('city_item')) {
            const element = $(event.target).hasClass('city_item') ? $(event.target) : $(event.target).closest('li');
            if (this._selectedCityElement) $(this._selectedCityElement).removeClass('_selected');

            this._selectedCityElement = element;
            $(element).addClass('_selected');

            this.$inputElement.val($(element).data('name'));
            this.$locationWrapper.removeClass('_show');

            this._changeDatepickerHandler();
            return;
        }
    }

    _showLoader() {
        if ($('.location_loader').length > 0) return;
        this.$locationWrapper.html('<div class="location_loader"><div></div><div></div><div></div><div></div></div>');
    }
}
export class ItemsWrapper {
    constructor({ page, handler }) {
        this.$page = page;

        this.$resultWrapper = $(this.$page).find('.bus_results_list');
        this._handler = handler;

        this._init();
    }

    _init() {
        $(document).on('click', '.details_btn', function () {
            $(this).toggleClass('_open').closest('.bus_results_item').find('.bus_results_item_details').slideToggle();
        });

        $(document).on('click', '.stops_title', function () {
            $(this).closest('.route').toggleClass('_open_stops');
            $(this).closest('.stops').toggleClass('_open').find('.stops_list').slideToggle();
        });

        $(document).on('click', '[data-popup-return]', function () {
            $('html').addClass('_open_bus_popup');
            $('.bus_overlay').addClass('_active');
            $('#bus_popup_return').addClass('_active');
        });

        $(this.$resultWrapper).on('click', 'button.save-route__btn', this._handler);
    }

    drawRoutes(view) {
        this.$resultWrapper.html(view);
    }

    removeRoutes() {
        this.$resultWrapper.html('');
    }

    appendRoute(route) {
        this.$resultWrapper.append(route);
    }

    hide() {
        $(this.$resultWrapper).hide();
    }

    show() {
        $(this.$resultWrapper).show();
    }
}
export class BusReturnTicketSelector {
    constructor({ datepicker }) {
        this._datepicker = datepicker;

        this._init();
    }

    _init() {
        $(document).on('click', '[data-select-return]', (e) => {
            e.preventDefault();
            e.stopPropagation();

            $('html, body').animate({
                scrollTop: $('#widget_form').offset().top // Скролл к верху указанного элемента
            }, 0);

            this._datepicker.openBackwardCalendar();
        });
    }
}
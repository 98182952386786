import moment from "moment";

export class Sort {
    constructor({ page, itemsWrapper, updateRoutes, getRoutes }) {
        this.$page = page;

        this.$sortWrapper = this.$page.find('.sorts_wrap');
        this.$sortElement = this.$sortWrapper.find('.item');
        this.$mobileCurrentSort = this.$sortWrapper.find('.sorts_mob');

        this._itemsWrapper = itemsWrapper;
        this._updateRoutes = updateRoutes;
        this._getRoutes = getRoutes;

        this._types = {
            'price': 'price',
            'time_departure': 'time_departure',
            'time_arrival': 'time_arrival',
            'fast': 'fast',
        };

        this._init();
    }

    _init() {
        this.$mobileCurrentSort.on('click', this._toggleSortsWrapper.bind(this));
        $(this.$sortElement).on('click', this._sortHandler.bind(this));
    }

    _sortHandler(event) {
        event.stopPropagation();

        const sortType = $(event.target).data('type');

        this.$sortElement.removeClass('_active _down');
        $(event.target).addClass('_active _down');
        this.$mobileCurrentSort.text($(event.target).text());
        this._toggleSortsWrapper();

        switch (this._types[sortType]) {
            case this._types.fast: 
                this._fastSort();
                this._sort();
                break;
            case this._types.price:
                this._priceSort();
                this._sort();
                break;
            case this._types.time_departure:
                this._timeDepartureSort();
                this._sort();
                break;
            case this._types.time_arrival:
                this._timeArrivalSort();
                this._sort();
                break;
            default:
                break;
        }
    }

    _toggleSortsWrapper() {
        if ($(window).width() > 600) return false;
        this.$sortWrapper.find('.sorts').slideToggle();
    }

    _fastSort() {
        const routes = this._getRoutes();
        const sortRoutes = routes.sort((a, b) => {
            const aStartDate = new Date(a.datetime_departure);
            const aEndDate = new Date(a.datetime_arrival);
            const bStartDate = new Date(b.datetime_departure);
            const bEndDate = new Date(b.datetime_arrival);

            return (bStartDate - bEndDate) - (aStartDate - aEndDate);
        });
        
        this._updateRoutes(sortRoutes);
    }

    _priceSort() {
        const routes = this._getRoutes();
        const sortRoutes = routes.sort((a, b) => a.price - b.price);

        this._updateRoutes(sortRoutes);
    }

    _timeArrivalSort() {
        const routes = this._getRoutes();
        const sortRoutes = routes.sort((a, b) => {
            const aDate = moment(a.datetime_arrival).valueOf();
            const bDate = moment(b.datetime_arrival).valueOf();

            return aDate - bDate;
        });

        this._updateRoutes(sortRoutes);
    }

    _timeDepartureSort() {
        const routes = this._getRoutes();
        const sortRoutes = routes.sort((a, b) => {
            const aDate = moment(a.datetime_departure).valueOf();
            const bDate = moment(b.datetime_departure).valueOf();

            return aDate - bDate;
        });

        this._updateRoutes(sortRoutes);
    }

    _sort() {
        const routes = this._getRoutes();
        const sortItems = [];

        routes.forEach(route => {
            const findElement = $(this.$page).find(`.bus_results_item[data-routeid='${route.route_id}']`);

            sortItems.push(findElement);
        })

        this._itemsWrapper.removeRoutes();

        sortItems.forEach(item => {
            this._itemsWrapper.appendRoute(item);
        });
    }

    hide() {
        $(this.$sortWrapper).hide();
    }

    show() {
        $(this.$sortWrapper).show();
    }
}
import AjaxBuilder from "../../../../Common/AjaxBuilder";
import { CustomDatepicker } from "../../../../Common/CustomDatepicker/CustomDatepicker";
import { format } from 'fecha';

export class Datepicker {
    constructor({ searchModule, lang }) {
        this.$searchModule = searchModule;
        this._lang = lang;

        this.$startInput = $(this.$searchModule).find('.js-start-input');
        this.$startTextElement = $(this.$searchModule).find('#js-start-text');
        this.$endInput = $(this.$searchModule).find('.js-end-input');
        this.$endTextElement = $(this.$searchModule).find('#js-end-text');
        
        this._init();
    }

    _init() {
        this._startDate = window.routes && window.routes.departure_date ?  window.routes.departure_date : format(new Date(), 'YYYY-MM-DD');
        this._endDate = window.routes && window.routes.return_date ?  window.routes.return_date : null;

        this._datepicker = new CustomDatepicker({
            inputFrom: this.$startInput,
            inputFromText: this.$startTextElement,
            inputTo: this.$endInput,
            inputToText: this.$endTextElement,
            start: this._startDate,
            end: this._endDate,
            dateRangeSelectionMode: true,
            maxMonth: 12, 
            startOfWeek: "monday",
            i18n: this._lang === 'ru-ua' ? {
                "day-names-short": ["Вс", "Пт", "Вт", "Ср", "Чт", "Пт", "Сб"],
                "day-names": ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
                "month-names-short": ["Янв", "Февр", "Март", "Апр", "Май", "Июнь", "Июль", "Авг", "Сент", "Октб", "Нояб", "Дек"],
                "month-names": ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
                "there": 'Туда',
                "back": 'Обратно',
            } : {
                "day-names-short": ["Вс", "Пт", "Вт", "Ср", "Чт", "Пт", "Сб"],
                "day-names": ["Неділя", "Понеділок", "Вівторок", "Середа", "Четвер", "П'ятниця", "Субота"],
                "month-names-short": ["Січ", "Лют", "Бер", "Квіт", "Трав", "Чер", "Лип", "Сер", "Вер", "Жов", "Лис", "Гру"],
                "month-names": ["Січень", "Лютий", "Березень", "Квітень", "Травень", "Червень", "Липень", "Серпень", "Вересень", "Жовтень", "Листопад", "Грудень"],
                "there": 'Туди',
                "back": 'Назад',
            }
        });
    }

    getCalendarPrices(cityFromId, cityToId) {
        const date = this._datepicker.getFirstDayOfMonth(1);

        new AjaxBuilder({
            route: '/bus/calendar/prices/',
            data: {
                departure_city_id: cityFromId,
                arrival_city_id: cityToId,
                date,
            }
        }, 'GET').execute({
            success: this._getCalendarPricesSuccess.bind(this),
            error: this._getCalendarPricesError.bind(this),
        });
    }

    _getCalendarPricesSuccess(data) {
        if (Object.keys(data.response.prices).length < 1) return;

        this._datepicker.setCalendarPrices(data.response.prices);
    }

    _getCalendarPricesError(data) {
        console.log('Ошибка');
        console.log(data);
    }

    setDateStart(value = null) {
        this._datepicker.setStartDate(value);
        return this;
    }

    setEndDate(value = null) {
        this._datepicker.setEndDate(value);
        return this;
    }

    getStartDate() {
        return this._datepicker.getDate('start');
    }

    getEndDate() {
        return this._datepicker.getDate('end');
    }

    openBackwardCalendar() {
        this._datepicker.openBackwardCalendar();
    }
}